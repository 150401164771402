import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  loadScript(src: string, id: string): void {
    if (document.getElementById(id)) {
      return;
    }
    const script = document.createElement('script');
    script.id = id;
    script.src = src;
    script.async = true;
    document.head.appendChild(script);
  }

  unloadScript(id: string): void {
    const script = document.getElementById(id);
    if (script) {
      script.remove();
    }
  }
}
