import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { LoaderService } from './services/loader.service';
import { environment } from '../environments/environment';
import { ScriptLoaderService } from './services/script-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  constructor(router: Router, private loaderService: LoaderService, private scriptLoader: ScriptLoaderService) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (!!event.url) {
          if (event.url.startsWith('/customer')) {
            window.location.href = environment.link + '/#' + event.url;
          } else if (event.url.startsWith('/pages/login')) {
            window.location.href = environment.link + '/#' + event.url;
          } else if (event.url.startsWith('/pages/resetpassword')) {
            window.location.href = environment.link + '/#' + event.url;
          } else if (event.url.startsWith('/authentication')) {
            window.location.href = environment.link + '/#' + event.url;
          }
        }
      }

      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        if (event.url != '/pages/maintenance') {
          this.loaderService.isShowMaintenance(false);
        }

        if (!event.urlAfterRedirects.includes('/pages/payment')) {
          this.scriptLoader.loadScript('https://static.hotjar.com/c/hotjar-5209375.js?sv=6', 'hotjar');
        } else {
          this.scriptLoader.unloadScript('hotjar');
        }

        if (!event.urlAfterRedirects.includes('/pages/payment')) {
          this.scriptLoader.loadScript('https://salesiq.zohopublic.eu/widget', 'zoho');
        } else {
          this.scriptLoader.unloadScript('zoho');
        }
      }
    });
  }

  ngOnInit() {

  }
}